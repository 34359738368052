import { h } from 'preact'
import { useState } from 'preact/hooks'
import { css } from '../style'
import Header from './Header'
import Offline from './Offline'
import Chat from './Chat'
import CloseConfirm from './CloseConfirm'
import useChat from '../use-chat'

export default ({ isVerifiedUser, closeShell, config, getToken, chatStatus, isOpen, setIsOpen, errorMessage }) => {
	const chat = useChat({ config, getToken, isVerifiedUser });
	const [showConfirm, setShowConfirm] = useState(false);
	let support = 'pending' as 'pending'|'chat'|'offline';

	let onClose = ()=>{
		if(chatStatus !== 'offline' && chat.active) {
			setShowConfirm(true);
		}else{
			setIsOpen(false);
		}
	};

	if (isVerifiedUser) {
		if (chatStatus === 'online' || chatStatus === 'online_for_queue') {
			support = 'chat';
		} else if (chatStatus === 'offline') {
			support = 'offline';
		} else {
			support = 'pending';
		}
	} else {
		support = 'offline';
	}

    return (
		<div className={css`
				position: fixed;
				top: 0;
				height: 100%;
				right: ${isOpen ? 'var(--side-spacing)' : '-100%'} ;
				width: calc(100vw - var(--side-spacing)) ;
				max-width: var(--max-window-width);
				display: grid;
				grid-template-rows: auto 1fr;
				background: var(--widget-bg);
				border-radius: 8px;
				box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
				transition: 0.2s;
			`}
		>
			<Header onClose={onClose} />
			{
				errorMessage && <div className={css`
					color: hsl(0,0%,60%);
					background: hsl(0,0%,97%);
					padding: 2em;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: var(--font-size-large);
					font-weight: var(--font-weight-bold);
				`}><div>{errorMessage}</div></div>
			}
			{
				support === 'chat' && <Chat chat={chat}/>
			}
			{
				support === 'offline' && <Offline onClose={onClose}/>
			}
			{
				showConfirm && <CloseConfirm onCancel={()=> setShowConfirm(false)} onConfirm={()=> {
					chat.send({
						action: 'deactivate_chat',
						payload: {
							id: chat.chat_id,
						},
					})
					setShowConfirm(false);
				}}/>
			}
		</div>
	)
}