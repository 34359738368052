import { h } from 'preact'
import { useState } from 'preact/hooks'
import { css } from '../style'
import svgTrash from '../svg/trash-alt.svg'
import svgChevRight from '../svg/chevron-right.svg'

function Header({ uploadedCount, totalCount, isOpen, toggle }) {
	return (
		<div className={css`
			padding: 8px;
			display: grid;
			gap: 0.5em;
			grid-template-columns: 1fr auto;
			align-items: center;
		`}>
			<div className={css`
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			`}>{uploadedCount} of {totalCount} uploaded</div>
			<button type='button' aria-label={isOpen ? 'collapse' : 'expand'} onClick={toggle}>
				<span
					dangerouslySetInnerHTML={{ __html: svgChevRight }}
					aria-hidden={true}
					className={css`
						display: flex;
						align-items: center;
						justify-content: center;
						width: 24px;
						height: 24px;
						border-radius: 4px;

						&:hover {
							background: var(--icon-button-bg);
						}

						svg {
							height: 60%;
							flex-shrink: 0;
							transition: 0.2s transform;
							transform: rotate(${isOpen ? '-90deg' : '90deg'});
						}
					`}
				/>
			</button>
		</div>
	)
}

function Card({ file }) {
	return (
		<div className={css`
			position: relative;
		`}>
			{
				file.type.slice(0,5) === 'image' ? <div style={{backgroundImage: `url("${file.url}")`}} className={css`
					background-position: center center;
					background-size: contain;
					background-repeat: no-repeat;
					padding-top: 100%;
					background-color: rgba(0,0,0,0.1);
				`}/> : <div/>
			}
			<div className={css`
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;										
			`}>{file.name}</div>
			<button type='button' className={css`
				position: absolute;
				top: 0;
				right: 0;
			`} onClick={file.delete}>
				<span
					dangerouslySetInnerHTML={{ __html: svgTrash }}
					aria-hidden={true}
					className={css`
						display: flex;
						align-items: center;
						padding: 0.5em;

						svg {
							width: 1em;
							flex-shrink: 0;
						}
					`}
				/>
			</button>
		</div>
	)
}

function Main({ children }) {
	return (
		<div className={css`
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			gap: 0.5em;
			border-top: 1px solid rgba(0,0,0,0.1);
			padding: 8px;
			overflow: auto;
		`}>{children}</div>
	)
}

function Footer({onSend, isBusy}) {
	return (
		<div className={css`
			padding: 8px;
		`}>
			<button disabled={isBusy} type='button' onClick={onSend} className='button'>Send files</button>
		</div>
	)
}

export default function ({ files, isBusy, onSend }) {
	const totalCount = Object.keys(files).length;

	const [{
		isOpen,
	}, setState] = useState({
		isOpen: true,
	});

	if (totalCount === 0) return null;

	const uploadedCount = Object.keys(files).filter(key => files[key].uploaded).length;

	return (
		<div className={css`
			position: absolute;
			width: 100%;
			bottom: 100%;
			overflow: hidden;
		`}>
			<div className={css`
				margin: 8px 16px;
				background: #fff;
				border-radius: 10px;
				box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px;
			`}>
				<Header
					isOpen={isOpen} 
					toggle={()=> setState((state)=>({...state, isOpen: !state.isOpen}))}
					uploadedCount={uploadedCount}
					totalCount={totalCount}
				/>
				<div className={css`
					height: 250px;
					transition: height 0.2s;
					overflow: hidden;
					display: grid;
					grid-template-rows: 1f auto;
					${
						isOpen ? '' : 'max-height: 0px;'
					}
				`}>
					<Main>
						{
							Object.entries(files).map(([id, file]) => (
								<Card key={id} file={file}/>
							))
						}
					</Main>
					<Footer onSend={onSend} isBusy={isBusy}/>
				</div>
			</div>
		</div>
	)
}
