import { useState, useEffect } from 'preact/hooks'

const initState = {
	chat_id: null,
	thread_id: null,
	events: null,
	is_typing: null,
	customer_id: null,
	send: null,
	active: null,
	queue: null,
	form: null,
	wsStatus: 'pending' as 'pending'|'open'|'closed'|'error',
	users: null,
};

export default function({ config, getToken, isVerifiedUser }) {
	const [state, setState] = useState({...initState});

	useEffect(()=> {
		let ws;

		if (!isVerifiedUser) return;

		function connect() {
			getToken().then(token => {
				ws = new WebSocket(
					`wss://api.livechatinc.com/v${config.liveChat.apiVersion}/customer/rtm/ws?organization_id=${config.liveChat.organizationId}`
				)

				function send(opts) {
					if (ws.readyState == WebSocket.CLOSED || ws.readyState == WebSocket.CLOSING) {
						connect();
					} else {
						ws.send(JSON.stringify({ ...opts, version: config.liveChat.apiVersion }))
					}
				}

				ws.onopen = (event) => {
					setState(() => ({
						...initState,
						wsStatus: 'open',
					}));

					send({
						action: 'login',
						payload: {
							token: 'Bearer ' + token.accessToken,
							customer_page: {
								title: `${document.title} (${window.location.hostname})`,
								url: window.location.href,
							},
						},
					})
				}

				ws.onclose = () => {
					setState(state => ({
						...state,
						wsStatus: 'closed',
					}))
				}

				ws.onerror = () => {
					setState(state => ({
						...state,
						wsStatus: 'error',
					}))
				}

				ws.onmessage = (event) => {
					const e = JSON.parse(event.data)

					switch (e.action) {
						case 'login': {
							setState(state => ({
								...state,
								customer_id: e.payload.customer_id,
								send,
							}))

							if (isVerifiedUser) {
								send({
									action: 'update_customer',
									payload: config.visitor,
								})
							}

							send({
								action: 'list_group_statuses',
								payload: {
									group_ids: [config.liveChat.agentGroupId],
								},
							})

							const chat_id = e.payload?.chats?.length ? e.payload.chats[0].chat_id : false;

							if (chat_id) {
								send({
									action: 'get_chat',
									payload: {
										chat_id: chat_id,
									},
								})
							} else {
								send({
									action: 'start_chat',
									payload: {},
								})
							}

							break
						}
						case 'incoming_chat': {
							setState((state) => ({
								...state,
								chat_id: e.payload.chat.id,
								thread_id: e.payload.chat.thread.id,
								events: [...e.payload.chat.thread.events],
								active: e.payload.chat.thread.active,
								users: e.payload.chat.users,
							}))

							break
						}
						case 'get_chat': {
							if (e.payload.thread.active === false) {
								send({
									action: 'resume_chat',
									payload: {
										chat: {
											id: e.payload.id,
										},
									},
								})
							} else {
								setState((state) => ({
									...state,
									chat_id: e.payload.id,
									thread_id: e.payload.thread.id,
									events: [...e.payload.thread.events],
									active: e.payload.thread.active,
									queue: e.payload.thread.queue, // todo: test without queue
									users: e.payload.users,
								}))
							}


							break
						}
						case 'resume_chat': {
							setState((state) => ({
								...state,
								thread_id: e.payload.thread_id,
								form: null,
								active: true,
							}))

							break
						}
						case 'incoming_event': {
							setState((state) => {
								let nState = { ...state, is_typing: false };
								if (
									e.payload.chat_id === state.chat_id &&
									e.payload.thread_id === state.thread_id
								) {
									if (e.payload.event?.text?.slice(0,9) === '{offline}') {
										// todo, graceful switch-over to offline form
									} else {
										nState.events = [...nState.events, e.payload.event]
									}
								}

								// clear form when filled_form event received
								if (e.payload.event.type === 'filled_form') {
									nState.form = null;
								}

								return nState
							})

							break
						}
						case 'incoming_typing_indicator': {
							setState((state) => ({
								...state,
								is_typing: e.payload.typing_indicator.is_typing
							}))

							break;
						}
						case 'chat_deactivated': {
							setState((state) => ({
								...state,
								queue: null,
								active: false,
							}))

							send({
								action: 'get_form',
								payload: {
									group_id: 0, // todo: determine group_id programmatically
									type: 'postchat',
								},
							})

							break;
						}
						case 'get_form': {
							setState((state) => ({
								...state,
								form: e.payload.form,
							}))
						}
						case 'queue_position_updated': {
							setState((state) => ({
								...state,
								queue: e.payload.queue,
							}))
							break;
						}
						case 'chat_transferred': {
							setState((state) => ({
								...state,
								queue: e.payload.queue,
							}))
							break;
						}
						case 'user_added_to_chat': {
							if (e.payload.user.type === 'agent') {
								setState((state) => ({
									...state,
									queue: null,
									users: [
										...(state.users || []),
										e.payload.user,
									],
								}))
							}
						}
					}
				}
			});
		}

		connect();

		return ()=> {
			if (ws) {
				ws.close();
			}
		}
	}, [])

	return state;
}