import { h } from 'preact'
import { 
	useState, 
	useMemo,
	useEffect,
	useRef,
} from 'preact/hooks'
import CallToAction from './CallToAction'
import Shell from './Shell'
import GlobalCtx from './GlobalCtx'
import customerAuth from '@livechat/customer-auth'

function useGetToken(config) {
	return useMemo(()=>{
		return customerAuth({
			licenseId: config.liveChat.licenseId, 
			clientId: config.liveChat.clientId,
		}, 'production').getToken;

	}, [config])
}

export default ({ config }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [chatStatus, setChatStatus]: ['pending'|'online'|'offline'|'online_for_queue', any] = useState('pending');
	const isVerifiedUser = config.visitor.name || config.visitor.email;
	const getToken = useGetToken(config);
	const chatStatusRef = useRef(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const wrapperRef = useRef(null);

	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};

	}, [])

	useEffect(()=>{
		let timeout;
		let pollStatus = isVerifiedUser;

		function getChatStatus() {
			getToken()
			.then(token => {
				return fetch(`https://api.livechatinc.com/v${config.liveChat.apiVersion}/customer/action/list_group_statuses?organization_id=${config.liveChat.organizationId}`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + token.accessToken,
					},
					body: JSON.stringify({
						// The 'Customer Agents' chat group is id 3, which is a group was created
						// in order to exclude the 'bot' agent, which is always online.
						// Human agents need to be manually be added to the 'Customer Agents' group.
						group_ids: [config.liveChat.agentGroupId],
					}),
				})
			})
			.then(res => res.json())
			.then(res => {
				if (res?.groups_status[config.liveChat.agentGroupId] && res.groups_status[config.liveChat.agentGroupId] !== chatStatusRef.current) {
					chatStatusRef.current = res.groups_status[config.liveChat.agentGroupId];
					setChatStatus(res.groups_status[config.liveChat.agentGroupId]);
				}

				timeout = pollStatus && setTimeout(getChatStatus, config.getStatusInterval);
				setErrorMessage(null);
			})
			.catch(err => {
				timeout = pollStatus && setTimeout(getChatStatus, config.getStatusInterval);
				setErrorMessage('Sorry, chat could not connect.');
			})
		}

		if (pollStatus) {
			getChatStatus();
			return ()=> {
				pollStatus = false;
				clearTimeout(timeout);
			}
		}
	}, [isVerifiedUser])

	const handleClickOutside = (event) => {
		const path = event.path || (event.composedPath && event.composedPath());

		if (!path.includes(wrapperRef.current)) {
			setIsOpen(false);
		}
	};

	return (
		<div class={`root-wrapper`} ref={wrapperRef} >
			<GlobalCtx config={config} getToken={getToken} isVerifiedUser={isVerifiedUser}>

				{<Shell
						closeShell={()=>setIsOpen(false)}
						isVerifiedUser={isVerifiedUser}
						config={config}
						getToken={getToken}
						chatStatus={chatStatus}
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						// chatStatus={'online'} // NO COMMIT, for dev testing, change back to chatStatus
						errorMessage={errorMessage}
					/>
				}

				{!isOpen && <CallToAction
					onClick={()=>setIsOpen(true)}
					isVerifiedUser={isVerifiedUser}
					chatStatus={chatStatus}/>
				}

			</GlobalCtx>
		</div>
	)
}
