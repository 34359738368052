import { h } from 'preact'
import { css } from '../style'
import svgTimes from '../svg/times.svg'

export default function ({ onConfirm, onCancel }) {
	return (
		<div className={css`
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.5);
			transition: background-color 300ms cubic-bezier(0.33, 0, 0.67, 1) 0s;
		`}>
			<div className={css`
				border-radius: 20px;
				background: #fff;
				position: relative;
				box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 24px;
				margin: var(--gutter);

				padding: 35px 20px 20px;
				max-width: 382px;
				margin: 0 auto;
				top: 50%;
				transform: translate(0, -50%);
			`}>
				<button
					type='button'
					onClick={onCancel}
					aria-label='Confirm close'
					className={css`
						position: absolute;
						top: 10px;
    					right: 10px;
					`}
				>
					<span
						dangerouslySetInnerHTML={{ __html: svgTimes }}
						aria-hidden={true}
						className={css`
							display: flex;
							padding: 10px 14px;

							svg {
								width: 1em;
								flex-shrink: 0;
								fill: currentColor;
							}
						`}
					/>
				</button>

				<div className={css`
					color: #4D4E58;
					font-size: 2rem;
					font-style: normal;
					font-weight: 600;
					line-height: 36px;
				`}>Do you want to leave this chat?</div>

				<div className={css`
					display: flex;
					justify-content: end;
				`}>

					<button onClick={onConfirm} className={'btn btn-secondary '+css`
						margin-top: 30px;
						padding: 12px 24px;
					`} type='button'>Yes</button>

					<button onClick={onCancel} className={'btn btn-primary '+css`
						margin-top: 30px;
						margin-left: 8px;
						padding: 12px 24px;
					`} type='button'>No</button>

				</div>
			</div>
		</div>
	)
}
