import { h } from 'preact'
import { css } from '../style'
import svgLogo from '../svg/logo.svg'

export default function({ user = null, created_at = null, children = null }) {
	const isCustomer = user?.type === 'customer';

	return (
		<div className={css`max-width: 100%;`}>
			<div className={css`
				display: flex;
				gap: 10px;
			`}>
				{
					!isCustomer && <div className={css`
						flex-shrink: 0;
						svg {
							display: block;
							width: var(--logo-size);
							height: var(--logo-size);
						}
					`} dangerouslySetInnerHTML={{__html: svgLogo}}/>
				}
				<div className={css`
					flex-grow: 1;
					display: flex;
					flex-direction: column;
					gap: 8px;
				`+' '+(isCustomer ? css`
					text-align: right;
					align-items: end;
				` : css`
				`)}>
					{
						user && created_at && (
							<div style={{justifyContent: isCustomer ? 'end' : 'start'}} className={css`
								font-size: var(--font-size-small);
								color: hsl(0,0%,50%);
								display: flex;
								gap: 0.5em;
							`}>
								{!isCustomer && user?.name && <div>{user.name}</div>}
								<div>{
									Intl.DateTimeFormat('en-US', {
										hour: 'numeric', minute: 'numeric',
									}).format(new Date(created_at))
								}</div>
							</div>
						)
					}
					<div
						className={css`
								display: inline-block;
								white-space: pre-line;
								word-break: break-word;
								max-width: 100%;

								a {
									color: currentColor;
									text-decoration: underline;
								}

								${
									isCustomer ? `
										background: var(--customer-bubble-bg);
										color: var(--customer-bubble-text);
										padding: 0.75em 1em 0.9em;
										border-radius: 16px;
										border-bottom-right-radius: 0;
									  `
									: `
										color: var(--agent-bubble-text);
									  `
								}
							`}
					>
						{children}
					</div>
				</div>
			</div>
		</div>
	)
}