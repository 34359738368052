import { h } from 'preact'
import { useState } from 'preact/hooks'
import OfflineThanks from './OfflineThanks'
import OfflineForm from './OfflineForm'

export default function({ onClose }) {
	const [state, setState] = useState({
		subject: 'Help Request',
		text: '',
		email: '',
		name: '',
		isBusy: false,
		error: '',
		isSubmitted: false,
	})

	function close() {
		onClose();
	}

	async function submit(e) {
		e.preventDefault();

		setState((state) => ({
			...state,
			error: '',
			isBusy: true,
		}))

		let res;

		try {
			const subPrefix = 'Discover';

			res = await fetch('https://materialbankhelp.zendesk.com/api/v2/requests', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					request: {
						subject: `${subPrefix} - ${state.subject}`,
						comment: {
							body: `${state.text}\n\n------------------\nSubmitted from: ${location.href}`,
							// uploads: ['G0cK3zyukYwektNe4sqaUln1S']
						},
						requester: {
							name: state.name,
							email: state.email,
						},
					}
				}),
			})
			.then(res => res.json())
		} catch (err) {
			// do nothing
			console.error(err);
		}

		setState((state) => {
			const s = {...state, isBusy: false};

			if (res) {
				s.isSubmitted = true;
			} else {
				s.error = 'Sorry, something went wrong. Please try emailing us directly.'
			}

			return s;
		})
	}

	return state.isSubmitted ? (
		<OfflineThanks onClose={close}/>
	) : (
		<OfflineForm onSubmit={submit} state={state} setState={setState}/>
	)
}