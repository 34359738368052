import { h } from 'preact'
import { useLayoutEffect, useRef } from 'preact/hooks'
import { css } from '../style'
import Footer from './Footer'
import MsgRich from './MsgRich'
import MsgText from './MsgText'
import MsgSystem from './MsgSystem'
import MsgFile from './MsgFile'
import TypingIndicator from './TypingIndicator'
import Restart from './Restart'
import Rating from './Rating'

export default ({ chat }) => {
	const { send } = chat;
	const lastRef = useRef<null | HTMLDivElement>(null)

	useLayoutEffect(() => {
		if (lastRef.current) {
			lastRef.current.scrollIntoView({
				behavior: 'smooth',
			})
		}
	}, [chat.events, chat.is_typing, chat.form])

	const thread = [];

	chat.events?.forEach((x, i, arr) => {
		let msg = null;
		const user = chat.users?.find(user => user.id === x.author_id);

		switch (x.type) {
			case 'message':
				msg = <MsgText key={x.id} event={x} user={user}/>
				break;
			case 'file':
				msg = <MsgFile key={x.id} event={x} user={user}/>
				break;
			case 'system_message':
				msg = <MsgSystem key={x.id} event={x}/>
				break;
			case 'rich_message':
				msg = <MsgRich
						key={x.id}
						event={x}
						send={send}
						chat_id={chat.chat_id}
						thread_id={chat.thread_id}
						chatIsActive={chat.active}
						user={user}
					/>
				break;
		}

		thread.push(msg);
	})

	if (chat.is_typing) {
		thread.push(<TypingIndicator/>)
	}

	chat?.form?.fields.forEach(field => {
		if (field.type === 'rating') {
			thread.push(
				<Rating
					form_id={chat.form.id}
					field={field}
					send={send}
					thread_id={chat.thread_id}
					chat_id={chat.chat_id}
				/>
			)
		}
	})

	const lastItem = thread.pop();

	return (
		<div className={css`
			display: grid;
			grid-template-areas:
				"queued"
				"messages"
				"restart"
				"footer";
			grid-template-rows: auto 1fr auto auto;
			overflow: hidden;
		`}>
			{
				chat.queue && (
					<div className={css`
						grid-area: queued;
						padding: 15px;
						background: #fff;
						border-bottom: 1px solid hsla(240,5%,0%,0.1);
					`}>
						One of our representatives will be with you shortly. You are number <strong>{chat.queue.position}</strong> in the queue. Your wait time will be approximately <strong>{Math.round(chat.queue.wait_time / 60)}</strong> minute(s). Thank you for your patience.
					</div>
				)
			}
			<div
				className={css`
					background: var(--widget-bg);
					padding: var(--gutter);
					overflow: auto;
					grid-area: messages;
				`}
			>
				<div className={css`
					display: grid;
					grid-template-columns: 1fr;
					gap: 20px;
				`}>
					{thread}
					{lastItem && <div ref={lastRef}>{lastItem}</div>}
				</div>
			</div>
			{
				(chat.active === false || chat.wsStatus === 'closed') && (
					<div className={css`
						grid-area: restart;
					`}>
						<Restart onClick={()=>{
							send({
								action: 'resume_chat',
								payload: {
									chat: {
										id: chat.chat_id,
									}
								},
							})
						}}/>
					</div>
				)
			}
			{
				chat.active === true && (
					<div className={css`
						grid-area: footer;
					`}>
						<Footer send={send} chat_id={chat.chat_id}/>
					</div>
				)
			}
		</div>
	)
}