import { h } from 'preact'
import { css } from '../style'

export default function ({ onClose }) {
	return (
		<div className={css`
			padding: var(--gutter);
			padding-top: 30%;
		`}>
			<div>
				<div className={css`
					text-align: center;
					font-size: var(--font-size-large);
					font-weight: var(--font-weight-bold);
				`}>Thanks for reaching out</div>
				<div className={css`
					text-align: center;
					margin-top: 0.5em;
				`}>Someone will get back to you soon</div>
				<button
					type='button'
					onClick={onClose}
					className={'button '+css`margin-top: 2em;`}
				>Close Help</button>
			</div>
		</div>
	)
}
