import { h } from 'preact'
import { useRef, useState, useContext } from 'preact/hooks'
import { css } from '../style'
import Files from './Files'
import svgAttach from '../svg/paperclip.svg'
import { GlobalCtx } from './GlobalCtx'

export default function({ send, chat_id }) {
	const fileId = useRef(0);
	const { config, getToken} = useContext(GlobalCtx);

	const [state, setState] = useState({
		text: '',
		files: {},
		isBusy: false,
	})

	function change(e) {
		setState((state) => ({
			...state,
			text: e.target.value,
		}))
	}

	function submit(e) {
		e.preventDefault()
		send({
			action: 'send_event',
			payload: {
				chat_id,
				event: {
					type: 'message',
					text: state.text,
				}
			},
		})
		setState((state) => ({
			...state,
			text: '',
		}))
	}

	function sendFiles() {
		setState((state)=> ({
			...state,
			isBusy: true,
		}));

		const fileArray = Object.values(state.files) as any;

		Promise.all(fileArray.filter(file => file.url).map(file => {
			send({
				action: 'send_event',
				payload: {
					chat_id,
					event: {
						type: 'file',
						url: file.url,
					}
				},
			})
		})).then(values => {
			setState((state)=>({
				...state,
				isBusy: false,
				files: {},
			}))
		})
	}

	async function uploadFiles(e) {
		const token = await getToken();
		const newFiles = [];
		const queue = Array.from(e.target.files).map((file: File) => {
			const formData = new FormData()
			const id = ++fileId.current;
			
			formData.append('file', file)

			newFiles.push({
				id,
				name: file.name,
				type: file.type,
				uploaded: false,
				delete: ()=> {
					setState((state)=> {
						const files = {...state.files};
						delete files[id];
						return {
							...state,
							files,
						}
					})
				}
			});

			return fetch(`https://api.livechatinc.com/v${config.liveChat.apiVersion}/customer/action/upload_file?organization_id=${config.liveChat.organizationId}`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + token.accessToken,
				},
				body: formData
			})
			.then(res => res.json())
			.then(res => {
				setState((state) => {
					const files = {...state.files};
					files[id] = {...files[id], uploaded: true, url: res.url};
					return {...state, files};
				})
			})
			.catch(error => {
				console.error(error)
			})
		})

		setState(state => {
			const files = {...state.files};
			newFiles.forEach(f => files[f.id] = f);
			return {
				...state,
				files,
				isBusy: true,
			}
		})

		await Promise.all(queue).then(values => {
			setState((state)=>{
				return {
					...state,
					isBusy: false,
				}
			})
		})
	}

	return (
		<div className={css`
			position: relative;
			padding: var(--gutter);
			border-top: 1px solid var(--border-color);
		`}>
			<Files files={state.files} isBusy={state.isBusy} onSend={sendFiles} />
			<form
				onSubmit={submit}
				className={css`
					display: grid;
					grid-template-columns: 1fr auto auto;
					padding: 8px;
					background: hsl(0,0%,96%);
					border-radius: 2em;
				`}
			>
				<input
					placeholder='Write a message'
					value={state.text}
					onInput={change}
					className={css`
						flex: 1;
						padding: 0 0.5em;
					`}
				/>
				<label
					aria-label='Attach'
					className={css`
						margin-left: auto;
						position: relative;
						overflow: hidden;
						cursor: pointer;
						opacity: 0.5;

						&:hover {
							opacity: 1;
						}
					`}
				>
					<span
						dangerouslySetInnerHTML={{ __html: svgAttach }}
						aria-hidden={true}
						className={css`
							display: flex;
							align-items: center;

							svg {
								width: 2em;
								flex-shrink: 0;
								fill: currentColor;
							}
						`}
					/>
					<input 
					type='file'
					multiple={true}
					onChange={uploadFiles}
					aria-label='Attach'
					className={css`
						position: absolute;
						top: 0;
						left: 0;
						opacity: 0;
						pointer-events: none;
					`}/>
				</label>
				<button
					disabled={!state.text}
					className={css`
						margin-left: auto;
						background: hsl(0,0%,85%);
						border-radius: 2em;
						padding: 0.3em 1em;

						&:disabled {
							opacity: 0.4;
						}

						&:hover:not(:disabled) {
							background: hsl(0,0%,80%);
						}
					`}
				>
					Send
				</button>
			</form>
		</div>
	)
}
