import { h } from 'preact'
import { css } from '../style'

export default function ({ onClick }) {
	return (
		<div className={css`
			padding: 0 var(--gutter) var(--gutter);
		`}>
			<button type='button' onClick={onClick} className='button'>
				Start the chat again
			</button>
		</div>
	)
}
