import { h } from 'preact'
import { css } from '../style'

export default function({ event }) {
	let msg;

	switch (event.system_message_type) {
		case 'chat_transferred':
			msg = (
				<span>
					<span>You have been transferred to: </span>
					<span className={css`white-space: nowrap;`}>{event.text_vars.targets}</span>
				</span>
			)
			break;
		case 'manual_archived_customer': {
			msg = 'You have closed the chat.'
			break;
		}
		case 'rating.chat_commented':
			msg = `You left the following comment: ${event.text_vars.comment}`
			break;
		case 'rating.chat_rated':
			msg = `You rated our customer service as ${event.text_vars.score}.`
			break;
		default:
			msg = event.text;
			break;
	}

	return (
		<div className={css`
			color: rgb(112, 112, 112);
			text-align: center;
			padding: 1em;
		`}>{msg}</div>
	)
}