import { h } from 'preact'
import { css } from '../style'

export default function() {
	return (
		<div className={css`
			display: inline-block;
			padding: 0.75em 1em;
			border-radius: 20px;
			background: #fbfbfb;
			margin-right: 2em;
			border-bottom-left-radius: 5px;
			box-shadow: 0 2px 6px rgba(0,0,0,0.05);
		`}>
		<svg viewBox='0 0 60 40' height='16px' width='24px' className={css`

			@keyframes typing-indicator {
				0%, 66%, 100% {
					transform: translateY(0px);
				}
				13%, 20% {
				    transform: translateY(-10px);
				}
				34% {
				    transform: translateY(-2px);
				}
				40% {
				    transform: translateY(3px);
				}
				53% {
				    transform: translateY(-1px);
				}
			}
		`}>
			{
				[9,30,51].map((x,i)=>(
					<circle r='6' cy='20' cx={x} style={{'animation-delay': i*200+'ms'}} className={css`
						animation-timing-function: cubic-bezier(0, 0, 1, 1);
						animation-name: typing-indicator;
						animation-duration: 1500ms;
						animation-iteration-count: infinite;
						outline: 2px solid transparent; /* expanding the repaint area to work around a Safari repaint bug that was leaving artifacts while animating */
					`}></circle>
				))
			}
		</svg>
		</div>
	)
}