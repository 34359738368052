import { h } from 'preact'
import { css } from '../style'
import svgThumbsUp from '../svg/thumbs-up.svg'
import svgThumbsDown from '../svg/thumbs-down.svg'
import { useState } from 'preact/hooks'

function IconButton({isActive, icon, isError, ...props}) {
	return (
		<button {...props} style={{backgroundColor: isError ? '#ffdede' : null}} dangerouslySetInnerHTML={{__html: icon}} className={css`
			display: flex;
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: ${isActive ? 'var(--action-button-bg)' : 'var(--icon-button-bg)'};
			color: ${isActive ? 'var(--action-button-text)' : 'inherit'};
			border-radius: 4px;
			
			svg {
				fill: currentColor;
				display: block;
				width: 22px;
			}
		`}/>
	)
}

export default function ({ form_id, field, send, chat_id, thread_id }) {
	const [{
		isBusy,
		error,
		commentValue,
		ratingValue,
	}, setState] = useState({
		isBusy: false,
		error: null,
		commentValue: '',
		ratingValue: null,
	});

	function submit(e) {
		e.preventDefault();

		if (ratingValue === null) {
			setState(state => ({
				...state,
				error: 'Rating is required',
			}))
			return;
		}

		setState(state => ({
			...state,
			error: '',
			isBusy: true,
		}))

		send({
			action: 'send_event',
			payload: {
				attach_to_last_thread: true,
				chat_id,
				event: {
					fields: [],
					form_id,
					properties: {
						lc2: {
							form_type: 'postchat',
						},
					},
					type: 'filled_form',
				},
			},
		})

		send({
			action: 'update_thread_properties',
			payload: {
				chat_id,
				thread_id,
				properties: {
					rating: {
						comment: commentValue,
						score: ratingValue,
					},
				},
			},
		})
	}

	return (
		<form className={css`
			display: grid;
			grid-template-columns: 1fr;
			gap: 15px;
			background: #fff;
			padding: 12px var(--gutter);
			border-radius: 6px;
			box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px;
		`} onSubmit={submit}>
			<div className={css`
				display: grid;
				grid-template-columns: 1fr;
				gap: 7px;
			`}>
				<div>{field.label}<sup style={{color:'var(--error-color)', fontSize: '1.5rem'}}>*</sup></div>
				<div className={css`
					display: grid;
					grid-template-columns: auto auto 1fr;
					gap: 5px;
					align-items: center;
				`}>
					<IconButton
						isError={Boolean(error)}
						aria-label='Thumbs Up'
						type='button'
						icon={svgThumbsUp}
						isActive={ratingValue===1}
						onClick={()=>{
							setState(state => ({
								...state,
								ratingValue: 1,
								error: null,
							}));
						}}
					/>
					<IconButton
						isError={Boolean(error)}
						aria-label='Thumbs Down'
						type='button'
						icon={svgThumbsDown}
						isActive={ratingValue===0}
						onClick={()=>{
							setState(state => ({
								...state,
								ratingValue: 0,
								error: null,
							}));
						}}
					/>
					{
						error && <div className={css`
							color: var(--error-color);
							font-size: 12px;
						`}>{error}</div>
					}
				</div>
			</div>
			<div>
				<div>Any comments for us?</div>
				<textarea className={'textarea '+css`
				margin-top: 7px;
				height: 130px;
				resize: vertical;
			`} value={commentValue} onInput={e => {
					setState(state => ({
						...state,
						commentValue: e.currentTarget.value
					}));
				}}/>
			</div>
			<button type='submit' style={{opacity: ratingValue === null ? '0.5' : null}} className={'button '+(isBusy ? 'busy ' : '')}>Submit</button>
		</form>
	)
}
