import { h } from 'preact'
import { css } from '../style'
import svgArrow from '../svg/arrow-left.svg'

// function Btn(props) {
// 	return (
// 		<button
// 			type='button'
// 			className={css`
// 				margin-left: 10px;
// 				display: flex;
// 				align-items: center;
// 				padding: 0 5px;

// 				svg {
// 					width: 1em;
// 					flex-shrink: 0;
// 					fill: currentColor;
// 				}
// 			`}
// 			{...props}
// 		/>
// 	)
// }

export default function ({ onClose = null }) {
	return (
		<div
			className={css`
				padding: var(--gutter);
				background: var(--title-bar-bg);
				color: var(--title-bar-text);
				border-bottom: 1px solid var(--border-color);
			`}
		>
			<div className={css`
				position: relative;
				display: grid;
				align-items: center;
				min-height: var(--logo-size);
			`}>
				<button
					type='button'
					aria-label='Minimize'
					onClick={onClose}
					dangerouslySetInnerHTML={{__html: svgArrow}}
					className={css`
						background: hsl(0,0%,94%);
						width: var(--logo-size);
						height: var(--logo-size);
						position: absolute;
						top: 0;
						left: 0;
						border-radius: 100%;
						display: flex;
						align-items: center;
						justify-content: center;

						svg {
							width: 40%;
							fill: currentColor;
						}
					`}
				/>
				<div
					className={css`
						font-size: var(--font-size-title);
						font-weight: var(--font-weight-bold);
						text-align: center;
					`}
				>
					Need Help?
				</div>
			</div>
		</div>
	)
}