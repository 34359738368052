import { h } from 'preact'
import ChatBubble from './ChatBubble'
import { css } from '../style'

function Thumbnail({ event }) {
	const maxWidth = 250;
	const maxHeight = 300;
	let width = event.width;
	let height = event.height;

	if (width > maxWidth) {
		width = maxWidth;
		height = (maxWidth / event.width) * event.height;
	}

	if (height > maxHeight) {
		height = maxHeight;
		width = (maxHeight / event.height) * event.width;
	}

	return (
		<img src={event.thumbnail_url} alt={event.name} style={{
			width,
			height,
		}} className={css`
			border: 1px solid hsl(0,0%,75%);
			display: block;
			object-fit: contain;
		`}/>
	)
}

export default function({ event, user }) {
	return (
		<ChatBubble created_at={event.created_at} user={user}>
			<a href={event.url} target='_blank'>
				{
					event.thumbnail_url ? <Thumbnail event={event}/> : event.name
				}
			</a>
		</ChatBubble>
	)
}