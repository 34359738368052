import { h } from 'preact'
import { useMemo } from 'preact/hooks'

const rxUrl = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g

function parse(str) {
	const parts = [];
	let result;
	let lastIndex = 0;

	while ((result = rxUrl.exec(str)) !== null) {
		if (result.index !== lastIndex) {
			parts.push(['text', str.slice(lastIndex, result.index)]);
		}

		parts.push(['url', result[0]])

		lastIndex = rxUrl.lastIndex;
	}

	if (lastIndex < str.length) {
		parts.push(['text', str.slice(lastIndex, str.length)]);
	}

	return parts;
}

export default function({ text }) {
	const parsed = useMemo(()=> parse(text), [text])

	return (
		<div>
		{
			parsed.map(([type, value])=> {
				return type === 'url' ? <a href={value} target='_blank'>{value}</a> : value
			})
		}
		</div>
	)
}