import { h } from 'preact'
import { css } from '../style'

export default function({ isVerifiedUser, onClick, chatStatus }) {
	return (
		<button
			type='button'
			onClick={onClick}
			className={css`
				border-radius: 1.5rem;
				padding: 0.75rem 1.5rem;
				line-height: normal;
				background: var(--minimized-bg);
				color: var(--minimized-color);
				position: fixed;
				bottom: 32px;
				right: 32px;
				font-size: var(--minimized-font-size);
				font-weight: var(--minimized-font-weight);

				@media (max-width: 767px) {
					bottom: 20px;
					right: 20px;
				}
			`}
		>
			{isVerifiedUser && (chatStatus === 'online' || chatStatus === 'online_for_queue') ? 'Chat/Help?' : 'Help?'}
		</button>
	)
}