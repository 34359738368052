import { h } from 'preact'
import { useContext } from 'preact/hooks'
import ChatBubble from './ChatBubble'
import Linkify from './Linkify'
import { GlobalCtx } from './GlobalCtx'

export default function({ event, user }) {
	const { swap } = useContext(GlobalCtx).config;

	let greetingMatch = event.text.match(/^Hi (.*),$/);
	if(greetingMatch?.length > 0){
		if(greetingMatch[1] !== window._clc.visitor.name){
			event.text = `Hi ${window._clc.visitor.name},`
		}
	}

	return (
		<ChatBubble user={user} created_at={event.created_at}>
			{swap[event.text.toLowerCase()]?.text || <Linkify text={event.text}/>}
		</ChatBubble>
	)
}