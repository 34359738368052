import { h } from 'preact'
import { render } from 'preact'
import Root from './components/Root'
import deepmerge from 'deepmerge'
import { bind } from './style'

window._clc = deepmerge({
	liveChat: {
		agentGroupId: 3, // the group ID of human agents as configured in the livechat admin
		licenseId: 12308691,
		clientId: '500da78ed1da0e8b258fb960a622ab31',
		organizationId: '2a22902c-d695-4574-9f00-28e962ad6da1',
		apiVersion: '3.5',
	},
	visitor: {
		name: null,
		email: null,
	},
	getStatusInterval: 6000,
	style: {
		'--z-index': 500,
		'--max-window-width': '454px',
		'--gutter': '16px',
		'--border-color': 'hsl(0,0%,92%)',
		'--font-family': 'inherit',
		'--text-color': 'hsl(0,0%,20%)',
		'--font-size-small': '12px',
		'--font-size-normal': '14px',
		'--font-size-title': '15px',
		'--font-size-large': '18px',
		'--font-weight-bold': '500',
		'--logo-size': '34px',
		'--side-spacing': '20px',
		'--minimized-bg': 'rgb(255, 211, 60)',
		'--minimized-color': '#111',
		'--minimized-font-size': '1.35em',
		'--minimized-font-weight': '500',
		'--menu-bg': 'hsl(0,0%,94%)',
		'--input-border-color': 'hsl(0,0%,84%)',
		'--widget-bg': '#fff',
		'--action-button-bg': 'rgb(61, 61, 61)',
		'--action-button-text': '#fff',
		'--title-bar-bg': '#fff',
		'--title-bar-text': 'inherit',
		'--agent-bubble-text': 'inherit',
		'--customer-bubble-bg': 'rgb(61, 61, 61)',
		'--customer-bubble-text': '#fff',
		'--icon-button-bg': 'rgb(240, 240, 240)',
		'--error-color': 'rgb(217, 51, 17)',
	},
	swap: {},
	offlineMessage: "Our support agents are unavailable at the moment. Please tell us how we can help, and we'll get back to you via email with a response.",
}, window._clc || {});

function createRootNode(style) {
	const app = document.createElement('div');
	app.classList.add('livechat-root');
	const shadow = app.attachShadow({mode: 'open'});
	const styleEl = document.createElement('style');
	styleEl.style.display = 'none';
	styleEl.textContent = `
		@namespace vectors "http://www.w3.org/2000/svg";

		@media print {
			:host {
				display: none;
			}
		}

		:not(vectors|*) {
			all: unset;
			display: revert;
			box-sizing: border-box;
			cursor: revert;
			-webkit-text-fill-color: currentcolor;
		}

		:host {
			${
				Object.entries(style).map(([key,val])=> {
					return `${key}: ${val};`
				}).join('\r\n')
			}

			color: var(--text-color);
			font-family: var(--font-family);
			font-size: var(--font-size-normal);
			z-index: var(--z-index);
		}

		svg {
			height: intrinsic; /* Safari fix */
		}

		style {
			display: none; /* Chrome v54 & lower fix */
		}

		button {
		    cursor: pointer; /* Personal preference */
		}

		strong {
			font-weight: bold;
		}

		.button {
			background: var(--action-button-bg);
			color: var(--action-button-text);
			text-align: center;
			padding: 12px;
			border-radius: 6px;
			width: 100%;
		}

		.btn {
			display: flex;
			align-items: center;
			white-space: nowrap;
			border-radius: 24px;
			border-width: 0;
			padding: 0.75rem 1rem;
			font-size: 1rem;
			line-height: 1.5rem;
			transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
			transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter;
			transition-duration: 300ms;
			transition-timing-function: cubic-bezier(.4,0,.2,1);
		}

		.btn:hover {
			--tw-shadow: 0 0 #0000;
			box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
		}

		.btn-primary {
			--tw-bg-opacity: 1;
			background-color: rgba(77,78,88,var(--tw-bg-opacity));
			--tw-text-opacity: 1;
			color: rgba(255,255,255,var(--tw-text-opacity));
		}

		.btn-primary:hover {
			--tw-bg-opacity: 1;
			background-color: rgba(39,39,44,var(--tw-bg-opacity));
		}

		.btn-secondary {
			--tw-bg-opacity: 1;
			background-color: rgba(245,245,247,var(--tw-bg-opacity));
			--tw-text-opacity: 1;
			color: rgba(77,78,88,var(--tw-text-opacity));
		}

		.btn-secondary:hover {
			--tw-bg-opacity: 1;
			background-color: rgba(228,228,228,var(--tw-bg-opacity));
		}

		.input, .textarea {
			border: 1px solid var(--input-border-color);
			border-radius: 6px;
			padding: 1em;
			width: 100%;
			background: #fff;
		}

		.button[disabled] {
			opacity: 0.5;
			pointer-events: none;
			cursor: default;
		}

		@keyframes busy {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(359deg);
			}
		}
		.busy {
			cursor: default;
			pointer-events: none;
			position: relative;
			overflow: hidden;
		}
		.busy:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: inherit;
			z-index: 1;
		}
		.busy:after {
			color: inherit;
			content: '';
			display: block;
			width: 14px;
			height: 14px;
			border-radius: 100%;
			border: 3px solid transparent;
			border-bottom-color: currentColor;
			border-left-color: currentColor;
			border-right-color: currentColor;
			animation: busy 0.8s infinite linear;
			position: absolute;
			top: calc(50% - 10px);
			left: calc(50% - 10px);
			z-index: 1;
		}
	`;
	shadow.appendChild(styleEl);
	const renderRoot = document.createElement('div');
	shadow.appendChild(renderRoot);
	document.body.appendChild(app);
	bind(shadow);
	return renderRoot;
}

const rootNode = createRootNode(window._clc.style);

function rend() {
	render(<Root config={window._clc}/>, rootNode)
}

const validator = {
  get(target, key) {
    if (typeof target[key] === 'object' && target[key] !== null) {
      return new Proxy(target[key], validator)
    } else {
      return target[key];
    }
  },
  set (target, key, value) {
  	target[key] = value;
    rend();
    return true
  }
}

window._clc = new Proxy(window._clc, validator)

rend();