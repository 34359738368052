import { h } from 'preact'
import { useContext } from 'preact/hooks'
import { css } from '../style'
import ChatBubble from './ChatBubble'
import { GlobalCtx } from './GlobalCtx'

export default function({ event, send, chat_id, thread_id, chatIsActive, user }) {
	const { swap } = useContext(GlobalCtx).config;
	const els = []

	const cnBtn = css`
		border-radius: 2em;
		padding: 1em;
		background: var(--menu-bg);
		white-space: pre-line;
	    word-break: break-word;
	    text-align: center;

		&[disabled] {
			opacity: 0.5;
			pointer-events: none;
			cursor: default;
		}
	`

	event.elements.map((x) => {
		const btns = x?.buttons?.map((b) => {
			let btn = null;
			const text = swap[b.text]?.text || b.text;

			function click() {
				send({
					action: 'send_event',
					payload: {
						chat_id,
						event: {
							type: b.type,
							text,
							postback: {
								id: b.postback_id,
								thread_id,
								event_id: event.id,
								type: b.type,
								value: b.value,
							},
						},
					},
				})
			}

			switch (b.type) {
				case 'url': {
					const href = swap[b.text]?.value || b.value

					btn = (
						<a href={href} target='_blank' className={cnBtn}>
							{text}
						</a>
					);
					break;
				}
				default: {
					btn = (
						<button type='button' disabled={!chatIsActive} className={cnBtn} onClick={click}>
							{text}
						</button>
					)
					break;
				}
			}

			return btn;
		}) || [];

		els.push(
			<div>
				<ChatBubble user={user} created_at={event.created_at}>{x.title}</ChatBubble>
				<div
					className={css`
						margin-top: 2.5em;
						display: grid;
						gap: 0.7em;
						grid-template-columns: 1fr;
					`}
				>
					{btns}
				</div>
			</div>
		)
	})

	return <div className={css``}>{els}</div>
}